<template>
    <div class="qa-index-page">
        <full-loading ref="fullLoading"></full-loading>
        <!-- <asset-tabs :active="'qa'"></asset-tabs> -->
        <div class="page-tools-content">
            <div class="left">
                <el-button type="grey" size="small" round icon="el-icon-plus" v-permission="`tools:checkRouter:addDoubleDoc`" @click="addQABtnEvent(2)">双文档</el-button>
                <el-button size="small" round icon="el-icon-plus" v-permission="`tools:checkRouter:addSingleDoc`" @click="addQABtnEvent(1)">单文档</el-button>
                <el-button size="small" plain round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage('reload')">刷新</el-button><!-- :disabled="mergeBtnDisabled" -->
            </div>
            <div class="right">
                <div style="width:220px;">
                    <el-input placeholder="输入名称" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div style="width:124px;margin-left:10px;">
                    <el-select v-model="searchForm.sort" size="small" placeholder="排序方式">
                        <el-option key="1" value="1" label="按时间排序"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <card-list v-if="list.length>0"
            :type="`qa`"
            :list="list"
            @action="executeAction"></card-list>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info" v-if="queryType === 'search'">没有找到任务～</div>
                <div class="info" v-else>不止于规则，更有基于语义理解的智能检查</div>
                <div>
                    <el-button size="small" type="primary" round icon="el-icon-plus" v-permission="`tools:checkRouter:addDoubleDoc`" @click="addQABtnEvent(2)" v-if="queryType !== 'search'">双文档</el-button>
                    <el-button size="small" round icon="el-icon-plus" v-permission="`tools:checkRouter:addSingleDoc`" @click="addQABtnEvent(1)" v-if="queryType !== 'search'">单文档</el-button>
                </div>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog title="QA检查" :visible.sync="dialog.show" @close="closeAddQaDialog" destroy-on-close :width="'712px'" top="20px">
            <div class="dialog-content" v-if="userInfo && userInfo.featureConfig">
                <el-image style="display:none;" ref="qaFileTypeExample"
                    src="https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/excel.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195067&Signature=SLQhEo%2BAhrmElVC4qsbdOVU%2B9To%3D"
                    :preview-src-list="[
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/excel.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195067&Signature=SLQhEo%2BAhrmElVC4qsbdOVU%2B9To%3D',
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/external1.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195092&Signature=uJNP%2ByxOd9oojRqNZbYxxoRs7o0%3D',
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/external2.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195112&Signature=636q3UmMIw9ikmgoS5DklS1JNOM%3D',
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/external3.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195136&Signature=NdgUEp6bUo%2BlTioFMHEZqUbPMGw%3D'
                    ]"></el-image>
                <template v-if="dialog.type === 1">
                    <type-radio :list="typeRadioOptions" :active="typeRadioSelected" @change="typeRadioChange" style="justify-content: center;margin-bottom:15px;"></type-radio>
                    <div class="tips" v-if="typeRadioSelected === 'aligned'">
                        支持已经对齐的文档，{{dialog.supportAlignedFiles}}及外部审校格式(docx/doc)<a href="javascript:;" @click="$refs.qaFileTypeExample.clickHandler()">查看格式示例</a>，不超过{{userInfo.featureConfig.singleFileSize}}MB
                        <el-tooltip placement="top">
                            <div slot="content" style="max-width: 300px;line-height: 1.5;">按照原文字符消耗流量。如上传双文档，按照原文文档计；如上传段段对照单文档，按照单文档字符计。</div>
                            <span class="margin-l-10 color-link cursor-pointer">流量扣费规则</span>
                        </el-tooltip>
                    </div>
                    <div class="tips" v-if="typeRadioSelected === 'unaligned'">
                        支持尚未对齐的文档，{{dialog.supportUnalignedSingleFiles}}格式，不超过{{userInfo.featureConfig.singleFileSize}}MB
                        <el-tooltip placement="top">
                            <div slot="content" style="max-width: 320px;line-height: 1.5;">按照原文字符消耗流量。如上传双文档，按照原文文档计；如上传段段对照单文档，按照单文档字符计。</div>
                            <span class="margin-l-10 color-link cursor-pointer">流量扣费规则</span>
                        </el-tooltip>
                    </div>
                    <div class="upload-files-content">
                        <upload-files 
                            ref="sourceUpload"
                            title="上传文档" 
                            :size="userInfo.featureConfig.singleFileSize"
                            :accept="typeRadioSelected === 'aligned' ? dialog.acceptAligned : dialog.supportUnalignedSingle"
                            :multiple="typeRadioSelected === 'aligned'"
                            :params="{}"></upload-files>
                    </div>
                </template>
                <template v-else>
                    <div class="tips">
                        支持尚未对齐的文档，{{dialog.supportUnalignedFiles}}格式，不超过{{userInfo.featureConfig.singleFileSize}}MB
                        <el-tooltip placement="top">
                            <div slot="content" style="max-width: 320px;line-height: 1.5;">按照原文字符消耗流量。如上传双文档，按照原文文档计；如上传段段对照单文档，按照单文档字符计。</div>
                            <span class="margin-l-10 color-link cursor-pointer">流量扣费规则</span>
                        </el-tooltip>
                    </div>
                    <div class="upload-files-content-2">
                        <div><!-- .xlsx,.xls,.csv,.rtf, -->
                            <upload-files 
                                ref="sourceUpload"
                                :title="`上传原文`" 
                                :size="userInfo.featureConfig.singleFileSize"
                                :accept="dialog.acceptUnaligned" 
                                :multiple="false"
                                :params="{}"></upload-files>
                        </div>
                        <div>
                            <upload-files 
                                ref="targetUpload"
                                title="上传译文"
                                :size="userInfo.featureConfig.singleFileSize"
                                :accept="dialog.acceptUnaligned"
                                :multiple="false"
                                :params="{}"></upload-files>
                        </div>
                    </div>
                </template>
                <div class="language-direction">
                    <div style="width:110px;">
                        <el-select v-model="dialog.source" placeholder="" size="small" @change="sourceSelectChange">
                            <template v-for="value in LANGUAGE_DICT">
                                <el-option :key="value.key" :value="value.key" :label="value.name"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div @click="exchangeLanguage" style="cursor: pointer;">
                        <el-tooltip content="点击切换" placement="top">
                            <svg-icon name="ib-transition"></svg-icon>
                        </el-tooltip>
                    </div>
                    <div style="width:110px;">
                        <el-select v-model="dialog.target" placeholder="" size="small" @change="targetSelectChange">
                            <template v-for="value in LANGUAGE_DICT">
                                <el-option :key="value.key" :value="value.key" :label="value.name" :disabled="value.key === dialog.source"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
                <div class="check-tips">{{this.checkTips}}</div>
                <div class="term-upload-content">
                    <div class="flex flex-algin-center">
                        <div class="icon-content">
                            <svg-icon name="ib-warning"></svg-icon>
                        </div>
                        <div class="info-content">若想检查术语不一致，上传术语表(支持{{dialog.supportTermFiles}}格式)</div>
                    </div>
                    <div class="margin-t-10">
                        <upload-oss-block ref="termUpload" :accept="dialog.acceptTerm" :multiple="true" :showList="true">
                            <el-button plain round size="mini">上传术语表</el-button>
                        </upload-oss-block>
                    </div>
                </div>
                <div class="qa-setting">
                    <next-collapse :title="`QA设置`" :show="true">
                        <div class="margin-b-15"><el-checkbox v-model="isCheckAll" :indeterminate="isCheckAllIndeterminate" @change="qaSettingCheckAllChange">全选</el-checkbox></div>
                        <div class="block-title">规则QA设置</div>
                        <div class="option-list">
                            <template v-for="(item,index) in qaSettingBasicList">
                                <div class="item" :key="index">
                                    <el-checkbox v-model="item.selected" :label="item.description" @change="qaSettingCheckChange"></el-checkbox>
                                </div>
                            </template>
                        </div>
                        <div class="block-title">
                            智能QA设置
                            <!-- <el-tooltip content="升级到更高版本使用智能QA" placement="top">
                                <span style="color:#000;">
                                    <i class="el-icon-info"></i>
                                </span>
                            </el-tooltip> -->
                        </div>
                        <div class="option-list">
                            <template v-for="(item,index) in qaSettingAiList">
                                <div class="item" :key="index">
                                    <el-checkbox v-model="item.selected" :label="item.description" @change="qaSettingCheckChange"></el-checkbox>
                                </div>
                            </template>
                        </div>
                        <!-- <div style="font-size:12px;"><svg-icon name="ib-warning" style="color:#FDB500;"></svg-icon> 英文流利度（包括用词搭配、时态、单复数、句首大小、代词/冠词、英文流利度检查）</div> -->
                    </next-collapse>
                    <!--  -->
                </div>
            </div>
            <div slot="footer" style="text-align: center;">
                <el-button round plain size="small" @click="dialog.show = false">取消</el-button>
                <el-button round type="primary" size="small" :loading="dialog.loading" @click="startTaskEvent">QA检查</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteTask"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import FullLoading from '@/components/FullLoading';
import fileDownload from 'js-file-download';
import { toolsApi, qaApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
import AssetTabs from '../components/Tabs';
import CardList from '../components/CardList';
import UploadFiles from '@/components/UploadOss';
import UploadOssBlock from '@/components/UploadOssBlock';
import NextCollapse from '@/components/NextCollapse';
import AlertDialog from '@/components/AlertDialog';
import TypeRadio from '@/components/TypeRadio';
export default {
    components:{
        AssetTabs, 
        CardList, 
        UploadFiles, 
        AlertDialog, 
        FullLoading,
        NextCollapse,
        TypeRadio,
        UploadOssBlock,
    },
    data(){
        return {
            refreshLoading: false,
            listTimer: null,
            alertInfo:{
                message:'确定要删除这个任务吗？',
                type:'confirm', //alert ,confirm
                buttonName:'确认删除',
                buttonType:'danger',
            },
            dialog: {
                show: false,
                loading:false,
                uploadFileURL: toolsApi.uploadFile,
                batchNo: new Date().getTime(),
                source: 'zh',
                target: 'en',
                type: 1, // 1：单文档，2：双文档
                acceptAligned: '.docx,.doc,.xlsx,.xls,.tmx,.sdlxliff', // ,.xliff,.sdlxliff
                supportAlignedFiles: 'xlsx/xls/tmx/sdlxliff', // /xliff
                acceptUnaligned: '.docx,.doc,.xlsx,.ppt,.pptx,.txt', // ,.pdf,.xls,.ppt
                supportUnalignedFiles: 'docx/doc/xlsx/ppt/pptx/pptx/txt', // /pdf/xls/ppt
                supportUnalignedSingle: '.docx,.doc,.txt',
                supportUnalignedSingleFiles: 'docx/doc/txt', // /pdf
                acceptTerm: '.xlsx,.xls,.tbx,.txt',
                supportTermFiles: 'xlsx/xls/tbx/txt',

            },
            searchForm: {
                kw: '',
                sort: '1'
            },
            pageSize:20,
            pageNumber:1,
            queryType: 'init',
            list: [],
            total: 0,
            searchTimer: null,
            qaSettingBasicList:[],
            qaSettingAiList:[],
            isCheckAll: true,
            isCheckAllIndeterminate: false,

            typeRadioOptions:[{key:'aligned',name:'已对齐'},{key:'unaligned',name:'未对齐'}],
            typeRadioSelected:'aligned', 
            termTableList: {},
            checkTips: '',
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
		}),
	},
    methods:{
        initPage(type){
            if(type === 'init'){
                this.$refs.fullLoading.setShow();
            }
            if(type === 'reload'){
                this.refreshLoading = true;
            }
            let url = `${toolsApi.queryQATasklist}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}&keyword=${encodeURI(this.searchForm.kw)}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.pager.records;
                    this.total = res.data.pager.total;
                }
            }).finally(()=>{
                this.$refs.fullLoading.setHide();
                this.refreshLoading = false;
            })
        },
        exchangeLanguage(){
            let tmpOriginValue = this.dialog.source;
            let tmpTargetValue = this.dialog.target;
            this.dialog.source = tmpTargetValue;
            this.dialog.target = tmpOriginValue;
            this.setCheckTips();
        },
        sourceSelectChange(value){
            if(value === this.dialog.target){
                if(value==='zh'){
                    this.dialog.target = 'en';
                }else{
                    this.dialog.target = 'zh';
                }
            }
            this.setCheckTips();
        },
        targetSelectChange(){
            this.setCheckTips();
        },
        setCheckTips(){
            return;
            let rst = [];
            if(['zh','en'].indexOf(this.dialog.source)==-1){
                rst.push('原文');
            }
            if(['zh','en'].indexOf(this.dialog.target)==-1){
                rst.push('译文');
            }
            if(rst.length>0){
                this.checkTips = rst.join('、') + '只进行规则QA检查';
            }else{
                this.checkTips = '';
            }
        },
        addQABtnEvent(type){
            this.initQaConfigList();
            this.dialog.show = true;
            this.dialog.type = type;
            this.dialog.batchNo=new Date().getTime();
            this.isCheckAll = true;
            this.isCheckAllIndeterminate = false;
        },
        closeAddQaDialog(){
            this.qaSettingBasicList = [];
            this.qaSettingAiList = [];
            this.typeRadioSelected = 'aligned';
        },
        qaSettingCheckAllChange(val){
            // this.isCheckAll = val;
            this.isCheckAllIndeterminate = false;
            this.qaSettingBasicList.map((item, index)=>{
                this.qaSettingBasicList.splice(index, 1, {...item, selected: val});
            });
            this.qaSettingAiList.map((item, index)=>{
                this.qaSettingAiList.splice(index, 1, {...item, selected: val});
            });
        },
        qaSettingCheckChange(val){
            let self = this;
            let size = 0;
            self.qaSettingBasicList.map(item=>{
                if (item.selected){
                    size = size + 1;
                }
            });
            self.qaSettingAiList.map(item=>{
                if (item.selected){
                    size = size + 1;
                }
            });
            if(size === 0){
                self.isCheckAll = false;
                self.isCheckAllIndeterminate = false;
            }else if(size > 0 && size < (self.qaSettingBasicList.length + self.qaSettingAiList.length)){
                self.isCheckAll = false;
                self.isCheckAllIndeterminate = true;
            }else if(size === (self.qaSettingBasicList.length + self.qaSettingAiList.length)){
                self.isCheckAll = true;
                self.isCheckAllIndeterminate = false;
            }
        },
        startTaskEvent(){
            let sourceFiles = this.$refs.sourceUpload.getOssFiles();
            let targetFiles = (this.dialog.type === 2) ? this.$refs.targetUpload.getOssFiles() : [];
            if(sourceFiles.length === 0){
                this.$message.error(this.dialog.type === 1?'请上传文档':'请上传原文');
                return;
            }
            if(this.dialog.type ===2){
                if(targetFiles.length === 0){
                    this.$message.error('请上传译文');
                    return;
                }
            }

            //术语表
            let teFiles = this.$refs.termUpload.getOssFiles();
            /* Object.keys(this.termTableList).map(key=>{
                let item = this.termTableList[key];
                // console.log('xxx:::::', item);
                teFiles.push({objectName: item.filePath, fileName: item.name});
            }); */
            let originalFiles = {};
            if(this.dialog.type === 2){
                originalFiles = {
                    source: sourceFiles,
                    target: targetFiles,
                    term: teFiles
                }
            }else{
                originalFiles = {
                    bilingual: sourceFiles,
                    term: teFiles
                }
            }

            let qaSelectedList = [];
            let qaUnSelectedList = [];
            this.qaSettingBasicList.map(item=>{
                if(item.selected){
                    qaSelectedList.push({code:item.code,selected:item.selected})
                }else{
                    qaUnSelectedList.push({code:item.code,selected:item.selected})
                }
            });
            this.qaSettingAiList.map(item=>{
                if(item.selected){
                    qaSelectedList.push({code:item.code,selected:item.selected})
                }else{
                    qaUnSelectedList.push({code:item.code,selected:item.selected})
                }
            });
            if(qaSelectedList.length === 0){
                this.$message.error('请选择检查项');
                return;
            }
            this.dialog.loading = true;
            let url = toolsApi.startQATask;
            this.$ajax.post(url,{
                batchNo: this.dialog.batchNo, //new Date().getTime(),
                sourceLang: this.dialog.source,
                targetLang: this.dialog.target,
                aligned: this.dialog.type === 1 ? (this.typeRadioSelected === 'aligned' ? true : false) : false,
                toolType: 'QA',
                title: `${sourceFiles[0].fileName}`,
                options: qaUnSelectedList,
                originalFiles: originalFiles,
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success('任务已开始执行');
                    this.dialog.show = false;
                    this.initPage();
                }
            }).finally(()=>{
                this.dialog.loading = false;
                this.termTableList = {};
            }) 
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initPage();
        },
        executeAction(key,params){
            switch (key){
                case 'excel':
                    this.downloadResult(params);
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    this.selectedTaskIds = [params.taskId];
                    break;
                case 'goto':
                    if(params.dataVersion > 182) {
                        this.$router.push({path:'/tools/qa/detail-new',query:{id:params.taskId}});
                    }else{
                        this.$router.push({path:'/tools/qa/detail',query:{id:params.taskId}});
                    }
                    break;
            }
        },
        downloadResult(params){
            // let url = `${toolsApi.exportResult}?taskId=${params.taskId}&toolType=QA`;
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: params.title,
                metadataIdList: [params.taskId],
                metadataType: 'TOOLKIT_QA',
                moreOptions: {
                    filterType: null,
                },
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                
            });
        },
        deleteTask(){
            let url = toolsApi.deleteTask;
            this.$ajax.post(url,{
                ids:this.selectedTaskIds,
                toolType:'QA'
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success('任务已删除');
                    this.initPage();
                }
            })
        },
        inputSearchEvent(value){
            let self = this;
            if(value.length > 0){
                this.queryType = 'search';
            }else{
                this.queryType = 'init';
            }
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                self.initPage();
            },500);
        },
        initQaConfigList(){
            let url = `${qaApi.queryQaConfig}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let list = res.data.options;
                    list.map(item=>{
                        if(item.type === 'BASIC'){
                            this.qaSettingBasicList.push({...item, selected:true});
                        }else if(item.type === 'AI'){
                            this.qaSettingAiList.push({...item, selected:true});
                        }
                    });
                }
            })
        },
        typeRadioChange(item){
            this.typeRadioSelected = item.key;
        },
        /**术语表上传成功 */
        onUploadTermSuccess(response, file, fileList){
            // console.log(file);
            this.termTableList[file.uid] = {...response.data, name: file.name};
        },
        /**删除已上传的术语表 */
        onRemoveTerm(file,fileList){
            delete this.termTableList[file.uid];
        },
    },
    mounted(){
        let self = this;
        this.initPage('init');
        if(this.listTimer){
            clearInterval(this.listTimer);
        }
        this.listTimer = setInterval(()=>{
            self.initPage('reload');
        },5000);
    },
    destroyed(){
        clearInterval(this.listTimer);
    },
}
</script>
<style lang="scss" scoped>
.qa-index-page{
    // padding: 20px;
}
.dialog-content{
    > .tips{
        text-align: center;
        font-size: 12px;
        margin-bottom: 20px;
    }
    > .language-direction{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px; 
        svg{
            font-size: 20px;
            margin: 0 10px;
        }
    }
    > .upload-files-content{
    }
    > .upload-files-content-2{
        display: flex;
        > div{
            &:first-child{
                padding-right:40px;
            }
            flex: 1;
            min-width: 0;
        }
    }
    > .term-upload-content{
        background-color: #FDF5E6;
        border-radius: 8px;
        padding: 10px 15px;
        margin: 30px 0px 20px 0px;
        min-width: 0;
        .icon-content{
            color: #FDB500;
            font-size: 20px;
        }
        .info-content{
            margin-left: 5px;
            flex:1;
            font-size: 12px;
            line-height: 20px;
        }
        ::v-deep .el-upload-list__item-name{
            white-space: initial;
        }
    }
    .check-tips{
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
    }
}
.qa-setting{
    // padding: 0 20px;
    .block-title{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .option-list{
        display: flex;
        margin-bottom: 25px;
        flex-wrap: wrap;
        .item{
            width: 25%;
            padding: 10px 0;
        }
    }
}
</style>
